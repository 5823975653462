import '../Home/AuthenticatedHome.css';
import './ProductRecord.css';
import {Image, Row} from 'react-bootstrap'
import {OperationRecord} from "./OperationRecord";
import {datastoreFetch} from "../datastore";
import { useEffect, useState } from 'react';
import {useOktaAuth} from "@okta/okta-react";



/**
 * Draws a ComingSoonProductCard
 *
 * @returns {JSX.Element} a ActiveProduct component for a "coming soon" product.
 */
export const ComingSoonProductCard = ({ product, color, line }) => (
    <div className="product-card rect" style={{
        display: 'flex',
        flexDirection: 'column',
        borderLeftColor: product[5]
    }}>
        <p>
            <Image src="./coming-soon.png" alt={product[1]} width={285} height={156}/>
        </p>
    </div>
)


/**
 * Draws a ActiveProduct
 *
 * @returns {JSX.Element} a ActiveProduct component for an active product.
 */
export const ActiveProductCard = ({ product, color}) => (
    <div className="product-card rect" style={{
        borderLeftColor: color,
        flexDirection: 'column',
    }}>
        <h4 className="authenticated-home h4">{product[1]}</h4>
        <OperationRecord operation={product} />
    </div>
);


export const ProductRecord = ({product}) => {
    const [children, setChildren] = useState([]);
    const { authState } = useOktaAuth()
    const colorCode = product[5] ? product[5] : '#fcfcfc';

    useEffect(() => {
        if (!authState || !authState.isAuthenticated || !authState.idToken) {
          return
        }
        const fetchData = async () => {
            try {
                const accessToken = authState?.accessToken?.accessToken
                const result = await datastoreFetch(accessToken, product[0]);
                setChildren(result.children);
            } catch (error) {
                console.error('Error fetching children:', error);
            }
        };
        fetchData();
    }, [authState, product]);

    return (
        <div className="product-record">
            <Row>
                {children.map((child) => (
                    <OperationRecord operation={child} color={colorCode}/>
                    // <ActiveProductCard key={child[0]} product={child} color={colorCode} line={product[1]}/>
                ))}
            </Row>
        </div>
    );
};
