import React from 'react';

const BinocularsText = () => (
    <g name={"binocularstext"}>
        <text
            fontFamily="SourceSansProRegular, Source Sans Pro"
            fontSize="16"
            fontWeight="normal"
            fill="#767676"
        >
            <tspan x="48" y="288">
                Explore a range of APIs across
                our Group, Life, and Retirement Planning
                offerings, helping you and your
                organization get the data you need to succeed.
            </tspan>
        </text>
    </g>
);

export {BinocularsText};

