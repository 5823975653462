import React from 'react';
import './UnauthHero.css';

const UnauthHero = () => {
    const isMobile = window.innerWidth <= 414;
    const viewBox = isMobile ? "500 0 414 364" : "0 0 1024 364";

    return (
        <svg className="unauth-hero" viewBox={viewBox}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <image
                        className="unauth-hero-image"
                        x="0"
                        y="0"
                        xlinkHref={`${process.env.PUBLIC_URL}/head_FPO.png`}
                    />
                </g>
            </g>
        </svg>
    );
};
export { UnauthHero };