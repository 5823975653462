import { Row } from 'react-bootstrap'
import './RootRecord.css'
import './ProductRecord.css'
import {ProductRecord} from "./ProductRecord";


/**
 * Draws a ActiveProduct
 *
 * @returns {JSX.Element} a ActiveProduct component.
 *
 * If a product is active, display the operations.
 * If not, display the ComingSoonProductCard.
 */
export const ActiveProducts = ({product}) => {
return !product[4] && (
    <Row>
        <h2 className="authenticated-home h2">{product[1]}</h2>
        <ProductRecord product={product}/>
    </Row>
);
};


/**
 * Draws a root page view
 *
 * @returns {JSX.Element} The root pageview component.
 *
 * Don't display these as cards, but use child elements to display the products.
 */
export const RootRecord = (root) => (
    <>
        <hr/>
        <div className="root-record">
            <Row>
                {
                    root.record.children.map((item) => (
                        // dental, life, retirement
                        <Row key={item[0]}>
                            <ActiveProducts product={item}/>
                        </Row>
                    ))
                }
            </Row>
        </div>
    </>
)