import React from 'react'
import './Header.css'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavBar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { Form, FormControl, InputGroup } from 'react-bootstrap'
import { useState } from 'react'
import { datastoreSearch } from '../datastore'
import { useStateDispatch } from '../applicationState'
import { useNavigate } from 'react-router-dom'


export const registrationUrl = process.env.REACT_APP_REGISTRATION_URL || 'https://access-d.ameritas.com/home/bookmark/0oae96hqn9IlmLI4z1d7/2557'

/**
 * Draws Logout Button and search bar if the user is logged in.
 *
 * @returns {JSX.Element} The navigation menu component.
 */
export const IsAuthenticatedHeader = () => {
  const { oktaAuth } = useOktaAuth()
  const logoutUrl = window.location.origin + '/';
  const logout = () => oktaAuth?.signOut({postLogoutRedirectUri: logoutUrl})

  return (
    <>
      <Nav.Item>
        <Link to="https://www.ameritas.com/contact/">
          <Button variant="dark">Contact Us</Button>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/">
          <Button variant="primary" onClick={logout}>Sign Out</Button>
        </Link>
      </Nav.Item>
    </>
  )
}


/**
 * Draws Register and Login Buttons if the user isn't logged in.
 *
 * @returns {JSX.Element} The navigation menu component.
 */
export const IsNotAuthenticatedHeader = () => {
  const { oktaAuth } = useOktaAuth()
  const login = () => oktaAuth?.signInWithRedirect()
  // const register = () => window.location = registrationUrl
  return (
    <>
      {/* <Nav.Item>
        <Button variant="dark" onClick={register}>Register</Button>
      </Nav.Item> */}
      <Nav.Item>
        <Button variant="primary" onClick={login}>Sign In</Button>
      </Nav.Item>
    </>
  )
}


/**
 * Draws some menu choices if the user is logged in.
 *
 * @returns {JSX.Element} The navigation menu component.
 */
export const IsAuthenticatedSubHeader = () => {
  const { oktaAuth } = useOktaAuth()
  const { dispatch } = useStateDispatch()
  const [searchQuery, setSearchQuery] = useState('')
  const navigate = useNavigate()

  const handleSearch = async event => {
    event.preventDefault()
    try {
      const response = await datastoreSearch(oktaAuth?.getAccessToken(), searchQuery)
      setSearchQuery('')
      dispatch({ type: 'SEARCH_RESULTS', results: { searchQuery: searchQuery, results: response } })
      navigate('/search')
    } catch (error) {
      console.error("Error:", error)
    }
    return false
  }

  return (
    <>
      <Form onSubmit={handleSearch}>
        <InputGroup size="md">
          <FormControl
            className="search"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            placeholder="What can we help you find?"
            aria-label=""
            aria-describedby="basic-addon1" />
          <Button variant="primary" type="submit">Search</Button>
        </InputGroup>
      </Form>
    </>
  )
}


/**
 * Draws a simple nagivation menu at the top of the page.
 *
 * @returns {JSX.Element} The navigation menu component.
 */
export const Header = () => {
  const { authState } = useOktaAuth();
  return (
    <>
      <div className="header">
        <Container>
          <Row className="header">
            <Col>
              <NavBar>
                <Nav>{authState?.isAuthenticated ? IsAuthenticatedHeader() : IsNotAuthenticatedHeader()}</Nav>
              </NavBar>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="subHeader">
        <Container>
          <Row>
            <Col className="flex-grow-1">
              <Link to="/">
                <Image src="/logo_header_@2x.png" width={173} height={60} alt="Ameritas" />
              </Link>
            </Col>
            <Col xs="auto" className="search-bar">
              {authState?.isAuthenticated ? IsAuthenticatedSubHeader() : <></>}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
