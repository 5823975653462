import { Col, Row } from "react-bootstrap"
import { PageView } from "../PageView/PageView"
import "./AuthenticatedHome.css"


/**
 * "Home - if you're authenticated"
 *
 * @returns {JSX.Element} The home page component.
 */
export const AuthenticatedHome = () => (
  <Row>
    <Col className="authenticated-home">
        <h1 className="authenticated-home h1">Ameritas Developer Portal</h1>
        <div>
            <PageView recordId="root" />
        </div>
    </Col>
  </Row>
)
