import "./PageView.css"
import { useOktaAuth } from '@okta/okta-react'
import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { RootRecord } from './RootRecord'
import { ProductRecord } from './ProductRecord'
import { OperationRecord } from './OperationRecord'
import { ApiRecord } from './ApiRecord'
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs"
import { datastoreFetch } from "../datastore"
import { DelayedLoading } from "../DelayedLoading/DelayedLoading"


/**
 * Draws a page view
 * recordId is initially set to 'root' in AuthenticatedHome.js
 *
 * @returns {JSX.Element} The page component.
 */
const PageView = ({ recordId }) => {
  const params = useParams()
  const { authState } = useOktaAuth()
  const recordIdToLoad = recordId || params.recordId
  const [breadcrumbs, setBreadcrumbs] = useState(null)
  const [component, setComponent] = useState((<DelayedLoading />))
  useEffect(() => {
    if (!authState || !authState.isAuthenticated || !authState.idToken) {
      return
    }
    // setComponent((<DelayedLoading />));
    const fetchData = async () => {
      const accessToken = authState?.accessToken?.accessToken
      const result = await datastoreFetch(accessToken, recordIdToLoad)
      // const result = await datastoreFetchAll(accessToken);
      setBreadcrumbs(result.breadcrumbs)
      if (result.recordType === 'ROOT') {
        setComponent(<RootRecord record={result} />)
      } else if (result.recordType === 'PRODUCT') {
        setComponent(<ProductRecord record={result} />)
      } else if (result.recordType === 'OPERATION') {
        setComponent(<OperationRecord record={result} />)
      } else if (result.recordType === 'API') {
        setComponent(<ApiRecord record={result} />)
      } else {
        const json = JSON.stringify(result, null, 4)
        setComponent((
          <>
            <h1>Unknown record type!</h1>
            <pre>{json}</pre>
          </>
        ))
      }
    }
    fetchData()
  }, [authState, recordIdToLoad])
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {component}
    </>
  )
}

export { PageView }
