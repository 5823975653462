import './App.css'
import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom"
import { Header } from './Header/Header'
import { Home } from './Home/Home'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { StateProvider } from './applicationState'
import { LoginCallback, Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { PrivateRoute } from './PrivateRoute/PrivateRoute'
import { PageView } from './PageView/PageView'
import { SearchResults } from './SearchResults/SearchResults'
import { Footer } from './Footer/Footer'
// import { ContactUs } from './ContactUs/ContactUs'


const loginCallbackPath = '/login/callback'

const oktaConfig = {
  issuer: process.env.REACT_APP_ISSUER || 'https://access-d.ameritas.com/oauth2/aus8fc5c2h6r4gTuP1d7',
  clientId: process.env.REACT_APP_CLIENT_ID || '0oae8etzq9iMEMfOy1d7',
  redirectUri: window.location.origin + loginCallbackPath,
  scopes: ['openid', 'developer.portal'],
  pkce: true
}


/**
 * The main application component.
 *
 * @returns {JSX.Element} The root of the application.
 */
const App = () => {
  const navigate = useNavigate()
  const oktaAuth = new OktaAuth(oktaConfig)
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin))
  }
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <StateProvider>
        <div id='root'>
          <div className='main-content'>
            <Header />
            <Container>
              <Row>
                <Col>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path={loginCallbackPath} element={<LoginCallback />} />
                    <Route path="/pages/:recordId" element={<PrivateRoute />}>
                      <Route path="/pages/:recordId" element={<PageView />} />
                    </Route>
                    {/* <Route path="/contact" element={<PrivateRoute />}>
                      <Route path="/contact" element={<ContactUs />} />
                    </Route> */}
                    <Route path="/search" element={<PrivateRoute />}>
                      <Route path="/search" element={<SearchResults />} />
                    </Route>
                  </Routes>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </StateProvider>
    </Security>
  )
}

export default App
