import React from 'react';

const HighFive = () => (
    <svg>
        <g name={"high-five"} transform="translate(72, -12) scale(.75)">
            <g>
                <path className="cls-1" d="M26.9 105.7V90.8v14.9Z"/>
                <path className="cls-2"
                      d="M154.7 27.1c-10.3 0-18.6 8.3-18.6 18.6s8.3 18.6 18.6 18.6 18.6-8.3 18.6-18.6-8.3-18.6-18.6-18.6Zm0 31.2c-6.9 0-12.6-5.6-12.6-12.6s5.6-12.6 12.6-12.6 12.6 5.6 12.6 12.6-5.6 12.6-12.6 12.6Z"/>
                <path className="cls-1"
                      d="M61.3 27.1c-10.3 0-18.6 8.3-18.6 18.6S51 64.3 61.3 64.3 79.9 56 79.9 45.7s-8.3-18.6-18.6-18.6Zm0 31.2c-6.9 0-12.6-5.6-12.6-12.6s5.6-12.6 12.6-12.6 12.6 5.6 12.6 12.6-5.6 12.6-12.6 12.6Z"/>
                <path className="cls-2"
                      d="M189 82.1c-.1-6.3-3.2-11-8.8-13.8-2.7-1.4-5.7-2.4-8.6-3.5-1.3-.5-2.5-.2-3.5.8l-12.9 13.2c-2.3-2.8-4.5-5.4-6.6-8-16.6-20.4-34.3-41.2-34.3-41.2l-4.2 4.3s30.1 36.4 41.8 50.5c1.7 2 3.4 2.1 5.3.2 4.4-4.3 8.7-8.6 13-13 .5-.6.9-.7 1.7-.4 1.6.7 3.3 1.3 5 2 3.6 1.5 5.8 4.8 5.8 8.7v38.4c0 2.1-.6 4-1.9 5.7-1.1 1.5-2.5 2.6-4.4 3.2V92.1c-.1-1.5-1.1-2.6-2.6-2.8-1.4-.2-2.8.6-3.2 1.9-.2.6-.2 1.4-.2 2.1v89.4h-12.5v-52.6c0-1.9-1-3.1-2.7-3.3-1.7-.2-3.2 1-3.3 2.7v53.2h-12.7V83.2c0-.9-.2-2-.7-2.7l-20.1-25.8c-.8-.9-1.8-1.3-3-1.1-2.4.5-3.2 3.1-1.6 5.2 6.2 7.9 12.3 15.8 18.5 23.7.5.7.8 1.5.8 2.4v97.4c0 3.7 2.5 6.8 6.7 6.8h30.1c4 0 6.6-2.6 6.6-6.7v-45.2c0-1 .2-1.5 1.3-1.8 6.6-1.8 11.2-7.9 11.2-14.7V82.1Z"/>
                <path className="cls-1"
                      d="M110.1 33.9c.9 1.1 1.8 2.3.4 4.2-5.3 6.9-23.7 30.1-32.8 41.7-.7 1-1.1 1.9-1.1 3.1v99.9H64.1v-52.7c0-1.9-1-3.1-2.7-3.3-1.7-.2-3.2 1-3.3 2.7v53.2H45.4V92.8c0-2.4-1.4-3.8-3.4-3.5-1.5.2-2.6 1.3-2.6 2.8v36.3c-1.7 0-6.4-2.9-6.4-8.2V86.5c-.2-7.6 1.8-10 4.1-11.4 1.5-.9 4.4-2.5 6.2-3.5.9-.5 2-.3 2.7.4 2.7 2.7 9.1 9.2 12.5 12.6 1.7 1.8 3.6 1.7 5.1-.2L105 34.3c1.4-1.7 3-1.9 5-.5l4.2-4.3c-3.5-3.9-10.1-3.6-13.6.7-11.8 14.3-23.7 28.7-35.5 43-1.4 1.7-2.8 3.4-4.3 5.2-4.4-4.3-8.7-8.6-12.9-12.9-1.1-1.1-2.4-1.4-3.9-.8-2.6 1-5.3 1.9-7.8 3.1-1 .5-2 1-2.8 1.6-4.1 2.7-6.5 6.7-6.5 11.9v39.6c0 13.5 12.5 14.5 12.5 14.5v48.5c0 2.8 2.2 5 5 5h33.5c2.3 0 5-2.2 5-5V84.5c0-.5.2-1.3.5-1.7 8.5-10.9 16.9-21.7 25.4-32.6 2.4-3 7.2-9.3 7.2-9.3l-5.9-7.1Z"/>
                <path className="cls-1" d="M57.9 185.8h.1v1.1h-.1z"/>
            </g>
        </g>
    </svg>
        );

        export {HighFive};
