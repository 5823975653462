/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

const UnauthHomeUpperText = ({ style }) => {
  const { oktaAuth } = useOktaAuth();
  const handleSignIn = (event) => {
    event.preventDefault();
    oktaAuth.signInWithRedirect();
  };
  const isMobile = window.innerWidth <= 768;
  const viewBox = "0 0 379 392";
  const transform = isMobile ? "scale(.95)" : "scale(.5)";

  return (
    <svg className="unauth-home-upper-text"
         style={{ ...style }}
         viewBox={viewBox}
         >
      <defs>
        <rect />
        <filter filterUnits="objectBoundingBox">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.25 0" type="matrix" in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g  transform={transform}>
        <g stroke="none" strokeWidth="1" fill="white" fillRule="evenodd">
          <g>
            <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
            <rect x="0" y="0" width="100%" height="100%" rx="8" stroke="#D3D3D3" strokeWidth="2" />
          </g>
          <text fontFamily="SourceSansPro-Bold, Source Sans Pro" fontSize="40" fontWeight="bold" fill="#333333">
            <tspan x="24" y="63">Unlock the Power{' '}</tspan>
            <tspan x="24" y="117">of Integration with{' '}</tspan>
            <tspan x="24" y="171">Ameritas APIs</tspan>
          </text>
          <text fontFamily="SourceSansProRegular, Source Sans Pro" fontSize="16" fontWeight="normal" fill="#767676">
            <tspan x="24" y="214">Welcome to the Ameritas Developer Portal, where{' '}</tspan>
            <tspan x="24" y="238">innovation meets collaboration.{' '}</tspan>
          </text>
          <rect fill="#CCCCCC" x="24" y="270" width="344" height="1" />
          <text fontFamily="SourceSansProRegular, Source Sans Pro" fontSize="16" fontWeight="normal">
            <tspan x="24" y="311" fill="#767676">Already registered?</tspan>
            <tspan x="24" y="335" fontFamily="SourceSansProSemiBold, Source Sans Pro" fontWeight="500" fill="#3775D0" textDecoration="underline">
              <a onClick={handleSignIn} style={{ color: '#3775D0', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                Sign in to your account
              </a>
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export { UnauthHomeUpperText };
