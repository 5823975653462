import React, { useEffect } from "react"
import { Outlet } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react'
import { toRelativeUrl } from "@okta/okta-auth-js"


/**
 * Verifies that the user is logged in before allowing them to see the nested
 * component.  If they are not, it redirects them to log in.
 *
 * @returns {JSX.Element} The next component or redirect to OKTA.
 */
export const PrivateRoute = () => {
    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {
        if (!authState) {
            return;
        }
        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }

    }, [oktaAuth, authState]);

    if (!authState || !authState?.isAuthenticated) {
        return (<></>);
    }

    return (<Outlet />);
}
