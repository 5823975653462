import React from 'react';

const ComputerText = () => (
    <g name={"computertext"} transform="translate(0, 0)">
        <text
            fontFamily="SourceSansProRegular, Source Sans Pro"
            fontSize="16"
            fontWeight="normal"
            fill="#767676"
        >
            <tspan x="373" y="272">
                Gain insights and guidance through{' '}
                comprehensive documentation, tutorials,{' '}
                and sample code to kickstart your{' '}
                organization's integration journey.
            </tspan>
        </text>

    </g>
);

export {ComputerText};

