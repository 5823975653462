// src/Footer/Footer.js
import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import './Footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col xs={12} md={6} className="social-images">
            <Link to="https://www.linkedin.com/company/ameritas/" target="_blank">
              <Image className="social-image" src="/icon_34x34_nearWhite_linkedIn_@2x.png" alt="LinkedIn" height={34} width={34} />
            </Link>
            <Link to="https://www.facebook.com/ameritas" target="_blank">
              <Image className="social-image" src="/icon_34x34_nearWhite_facebook_@2x.png" alt="Facebook" height={34} width={34} />
            </Link>
            <Link to="https://www.instagram.com/ameritaslife" target="_blank">
              <Image className="social-image" src="/icon_34x34_nearWhite_instagram_@2x.png" alt="Instagram" height={34} width={34} />
            </Link>
            <div>&copy; {year} Ameritas Mutual Holding Company.
              <br />
              All rights reserved.
            </div>
          </Col>
          <Col xs={12} md={6} className="logo-image">
            <Image src="/logo_footer_@2x-1.png" height={58} width={278} alt="Ameritas" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export { Footer }