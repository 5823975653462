import { useEffect, useState } from "react"
import "./WsdlViewer.css"

export const WsdlViewer = ({ recordId }) => {
    const [formattedWSDL, setFormattedWSDL] = useState('')

    useEffect(() => {
        const fetchXml = (url) => {
            return fetch(url).then(response => {
                if (!response.ok) {
                    throw new Error('Unable to get the WSDL document.')
                }
                return response.text()
            })
        }

        const url1 = '/wsdl-viewer.xsl'
        const url2 = '/docs/' + recordId + '/wsdl.xml'

        Promise.all([fetchXml(url1), fetchXml(url2)]).then(values => {
            const [xslt, wsdl] = values
            const parser = new DOMParser()
            const xsltDoc = parser.parseFromString(xslt, 'text/xml')
            const wsdlDoc = parser.parseFromString(wsdl, 'text/xml')
            const xstlProcessor = new XSLTProcessor()
            xstlProcessor.importStylesheet(xsltDoc)
            const resultDoc = xstlProcessor.transformToDocument(wsdlDoc)
            const serializer = new XMLSerializer()
            const resultString = serializer.serializeToString(resultDoc)
            setFormattedWSDL(resultString)

        }).catch(error => {
            console.error('Error:', error)
        })
    }, [recordId])

    return (
        <div className="wsdlViewer" dangerouslySetInnerHTML={{ __html: formattedWSDL }}></div>
    )
}