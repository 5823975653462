import { Link } from 'react-router-dom'
import './OperationRecord.css';
import './ProductRecord.css'
import {datastoreFetch} from "../datastore";
import {useEffect, useState, Fragment} from "react";
import {useOktaAuth} from "@okta/okta-react";
import {Col, Row} from "react-bootstrap";


/**
 * Draws a operation page view
 *
 * @returns {JSX.Element} The operation pageview component.
 *
 * select the operation where the parent value is the operation name.
 */
export const OperationRecord = ({operation, color}) => {
    const [children, setChildren] = useState([]);
    const { authState } = useOktaAuth()

    useEffect(() => {
        if (!authState || !authState.isAuthenticated || !authState.idToken) {
          return
        }
        const fetchData = async () => {
            try {
                const accessToken = authState?.accessToken?.accessToken
                const result = await datastoreFetch(accessToken, operation[0]);
                setChildren(result.children);
            } catch (error) {
                console.error('Error fetching children:', error);
            }
        };
        fetchData();
    }, [authState, operation]);

    return (
        <Row>
            <h4 className="authenticated-home h4">{operation[1]}</h4>
            {children.map((child) => (
                    <Col xs={6} md={9} lg={12} key={child[0]} className="product-card rect" style={{
                        borderLeftColor: color, alignItems: 'left'
                    }}>
                        {(() => {
                            const parts = child[2].split(':');
                            return (
                                <>
                                    <p style={{alignItems: 'left', fontSize: '1.25em', fontWeight: 'bold', fontStyle: 'italic'}}>{parts[0]}</p>
                                    <p style={{alignItems: 'left', fontSize: '1.0em', fontWeight: 'normal', paddingLeft: '10px'}}>{parts[2]}</p>
                                </>
                            );
                        })()}
                        <Link className="operation-record link-api" to={`/pages/${child[0]}`}>API Reference</Link>
                    </Col>
                )
            )}
        </Row>
    );
};
