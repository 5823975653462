import { useEffect, useState } from "react"
import { Badge, Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { useStateDispatch } from "../applicationState"
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs"
import "./SearchResults.css"
import { useOktaAuth } from '@okta/okta-react'
import { datastoreSearch } from "../datastore"

export const Pill = ({ recordType }) => {
    switch (recordType) {
        case "PRODUCT":
            return <Badge pill bg="" className="pill-product">Product</Badge>
        case "OPERATION":
            return <Badge pill bg="" className="pill-operation">Operation</Badge>
        case "API":
            return <Badge pill bg="" className="pill-api">API</Badge>
        default:
            return <Badge pill bg="primary">{recordType}</Badge>
    }
}

export const SearchResults = () => {
    const { oktaAuth } = useOktaAuth()
    const { state } = useStateDispatch()
    const nav = useNavigate()
    const { dispatch } = useStateDispatch()
    const [secondSearchQuery, setSecondSearchQuery] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        if (!state.searchQuery) {
            nav('/')
            return
        }
    }, [nav, state])

    const handleSecondSearch = async event => {
        event.preventDefault()
        try {
            const response = await datastoreSearch(oktaAuth?.getAccessToken(), secondSearchQuery)
            setSecondSearchQuery('')
            dispatch({ type: 'SEARCH_RESULTS', results: { searchQuery: secondSearchQuery, results: response } })
            navigate('/search')
        } catch (error) {
            console.error("Error:", error)
        }
        return false
    }

    return (
        <>
            <Breadcrumbs breadcrumbs={[["root", "Home"], [null, "Search"]]}/>
            <br/>
            <br/>
            <h2>Search Results</h2>
            <Form onSubmit={handleSecondSearch}>
                <Row>
                    <Col sm={12} md="auto" className="search-center">
                        Showing results for:
                    </Col>
                    <Col sm={12} md={10}>
                        <InputGroup size="md">
                            <FormControl
                                className="search"
                                // value={state.searchQuery}
                                onChange={e => setSecondSearchQuery(e.target.value)}
                                defaultValue={state.searchQuery}
                                placeholder="What can we help you find?"
                                aria-label=""
                                aria-describedby="basic-addon1"/>
                            <Button variant="primary" type="submit">Search</Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
            <hr/>
            <div>
                {state.searchResults.map((result, index) => (
                    result.recordType !== 'ROOT' && (
                        <div key={index}>
                            <Link to={`/pages/${result.recordId}`}>
                                <h4>{result.name}</h4>
                            </Link>
                            <Pill recordType={result.recordType}/>
                            <div>
                                {result.description}
                            </div>
                            <br/>
                        </div>
                    )
                ))}
            </div>
        </>
    )
}