import { Table } from 'react-bootstrap'
import './ApiRecord.css'
import { RedocStandalone } from 'redoc'
import { WsdlViewer } from '../WsdlViewer/WsdlViewer'



/**
 * Draws a API page view
 *
 * @returns {JSX.Element} The API pageview component.
 */
export const ApiRecord = (api) => {
    const specUrl = api.record.apiStyle === 'REST' ? `/docs/${api.record.recordId}/openapi.json` : '';
    const docs = api.record.apiStyle === 'REST'
        ? (
            <div className="redoc">
                <RedocStandalone specUrl={specUrl} options={{ hideHostname: true }} />
            </div>
        )
        : (<WsdlViewer recordId={api.record.recordId} />)
    return (
        <>
            <h3>{api.record.name}</h3>
            <Table responsive>
                <tbody>
                    <tr>
                        <td>API Style</td>
                        <td>{api.record.apiStyle}</td>
                    </tr>
                    <tr>
                        <td>Auth Scheme</td>
                        <td>{api.record.securityRequirements.authScheme}</td>
                    </tr>
                    <tr>
                        <td>Required permissions</td>
                        <td>{api.record.securityRequirements.requiredPermissions.join(', ')}</td>
                    </tr>
                    <tr>
                        <td>URLs</td>
                        <td>{api.record.urls.map(entry => (
                            <div key={entry.environment}>
                                {entry.environment}
                                <br />
                                <a href={entry.url} target="_blank" rel="noreferrer">{entry.url}</a>
                            </div>
                        ))}</td>
                    </tr>
                    <tr>
                        <td>Last published on</td>
                        <td>{api.record.lastPublished}</td>
                    </tr>
                    <tr>
                        <td>Last published by</td>
                        <td>{api.record.publishedBy}</td>
                    </tr>
                    <tr>
                        <td>Tags</td>
                        <td>{api.record.keywords}</td>
                    </tr>
                </tbody>
            </Table>
            {docs}
        </>
    )
}