import React from "react"
import "./Home.css";
import { AuthenticatedHome } from "./AuthenticatedHome";
import { UnauthenticatedHome } from "./UnauthenticatedHome";
import { useOktaAuth } from '@okta/okta-react';



/**
 * "Home"
 *
 * @returns {JSX.Element} The home page component.
 */
const Home = () => {
    const { authState } = useOktaAuth();
    if (!authState || authState?.isPending) {
        return (<></>);
    }
    return authState?.isAuthenticated ? (<AuthenticatedHome />) : (<UnauthenticatedHome />);
};

export { Home };
