import React, { createContext, useContext, useReducer } from "react"


const initialApplicationState = () => {
  return {
    searchQuery: '',
    searchResults: []
  }
}


const incrementSecretVisitCount = (state) => {
  return { ...state, secretVisitCount: state.secretVisitCount + 1 }
}

const setSearchResults = (state, results) => {
  return { ...state, searchQuery: results.searchQuery, searchResults: results.results }
}


const applicationReducer = (state, action) => {
  var newState = state
  switch (action.type) {
    case 'SEARCH_RESULTS':
      newState = setSearchResults(state, action.results)
      break
    case 'SECRET_COUNT':
      newState = incrementSecretVisitCount(state)
      break
    default:
      break
  }
  return newState
}


const StateContext = createContext(null)


export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(applicationReducer, initialApplicationState())
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}


export const useStateDispatch = () => {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useStateDispatch must be used within a StateProvider')
  }
  return context
}
