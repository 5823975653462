import React from 'react';
import './UnauthGetStarted.css';
import { registrationUrl } from '../../Header/Header';


const UnauthGetStarted = ({ style }) => (
  <div className="get-started-container" style={style}>
    <svg viewBox="0 0 440 260" className="get-started-svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <text
              fontFamily="SourceSansProSemiBold, Source Sans Pro"
              fontSize="32"
              fontWeight="bold"
              fill="#333333"
          >
            <tspan className="text-large" x="96" y="31">
              Get started today!
            </tspan>
          </text>
          <text
              fontFamily="SourceSansProRegular, Source Sans Pro"
              fontSize="16"
              fontWeight="normal"
              fill="#767676"
          >
            <tspan x="15" y="74">
              Join a community of forward-thinking developers and elevate{' '}
            </tspan>
            <tspan x="60" y="98">
              your projects to new heights with Ameritas APIs.
            </tspan>
          </text>
          <g transform="translate(130, 132)">
            <rect fill="#377DD0" x="0" y="0" width="182" height="44" rx="4"/>
            <text
                fontFamily="SourceSansProSemiBold, Source Sans Pro"
                fontSize="16"
                fontWeight="500"
                fill="#FFFFFF"
            >
              <tspan x="12" y="28">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#" onClick={ registrationUrl } style={{ color: '#FFFFFF', textDecoration: 'none' }}>
                    Register for an Account
                  </a>
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export { UnauthGetStarted };