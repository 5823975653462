import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './UnauthUnlockRectangle.css';
import { Binoculars } from "./Binoculars";
import { Computer } from "./Computer";
import { HighFive } from "./HighFive";
import {BinocularsText} from "./BinocularsText";
import {ComputerText} from "./ComputerText";
import {HighFiveText} from "./HighFiveText";

const UnauthUnlockRectangle = ({ style }) => {
  const isMobile = window.innerWidth <= 768;

  return (
      <div className="unauth-unlock-rectangle" style={{...style}}>
          <Row className={isMobile ? 'flex-column' : 'flex-row'} style={{textAlign: 'center'}}>
              <tspan className="text-large">What are you unlocking exactly?</tspan>
              <Col>
                  <div className="centered-vertical">
                      <Binoculars/>
                      <BinocularsText/>
                  </div>
              </Col>
              <Col>
                  <div className="centered-vertical">
                      <Computer/>
                      <ComputerText/>
                  </div>
              </Col>
              <Col>
                  <div className="centered-vertical">
                      <HighFive/>
                      <HighFiveText/>
                  </div>
              </Col>
          </Row>
      </div>
  );
};

export {UnauthUnlockRectangle};