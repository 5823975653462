import {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import '../Home/UnauthenticatedHome.css';
import {UnauthUnlockRectangle} from './UnauthenticatedHome/UnauthUnlockRectangle';
import {UnauthHomeUpperText} from "./UnauthenticatedHome/UnauthHomeUpperText";
import {UnauthGetStarted} from "./UnauthenticatedHome/UnauthGetStarted";
import {UnauthHero} from "./UnauthenticatedHome/UnauthHero";

// login is contained in UnauthHomeUpperText.jsx
// register is contained in UnauthGetStarted.jsx

const UnauthenticatedHome = () => {

  useEffect(() => {
    fetch(`/api/warmup`)
      .then(response => response.json())
      .catch(error => console.error(error));
  });

  return (
        <Col>
            <Row>
                <div>
                    <UnauthHero />
                </div>
                <div>
                    <UnauthHomeUpperText />
                </div>
                <div>
                    <UnauthUnlockRectangle />
                </div>
                <div>
                    <UnauthGetStarted />
                </div>
              </Row>
        </Col>
  );
};

export { UnauthenticatedHome };