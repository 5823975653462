const records = new Map();

export const datastoreFetch = async (accessToken, recordId) => {
    if (records.size === 0) {
        await datastoreFetchAll(accessToken);
    }
    if (records.has(recordId)) {
        console.log(records.get(recordId));
        return records.get(recordId);
    }
    try {
        const response = await fetch(`/api/records/${recordId}`, {
            headers: { Authorization: `Bearer ${accessToken}`}
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch record with ID: ${recordId}`);
        }
        const result = await response.json();
        records.set(recordId, result);
        if (result.children) { // Go ahead and start pre-loading the children into the records cache.
            result.children.map(child => datastoreFetch(accessToken, child[0]));
        }
        return result;

    } catch (error) {
        console.error(`Error fetching record with ID: ${recordId}`, error);
        throw error;
    }
};

export const datastoreFetchAll = async (accessToken) => {
    if (records.size > 0) {
        return records;
    }
    try {
        const response = await fetch('/api/all', {
            headers: { Authorization: `Bearer ${accessToken}`}
        });

        if (!response.ok) {
            throw new Error('Failed to fetch records');
        }
        const results = await response.json();
        results.forEach(element => {
            records.set(element.recordId, element);
        });
        return records.get('root');
    } catch (error) {
        console.error('Error fetching records', error);
        throw error;
    }
}

export const datastoreSearch = async (accessToken, searchQuery) => {
    try {
        const response = await fetch('/api/records/search', {
            method: 'POST',
            headers: { 
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ search_string: searchQuery })
        });

        if (!response.ok) {
            throw new Error(`Failed to search for records with query: ${searchQuery}`);
        }
        return await response.json();

    } catch (error) {
        console.error(`Error searching for records with query: ${searchQuery}`, error);
        throw error;
    }


}