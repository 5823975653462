import React from 'react'
import "./Breadcrumbs.css"
import { Link } from 'react-router-dom'

// Look in backend datastore.py for the breadcrumbs logic
// breadcrumbs come in as a list of lists, like [["root", "Home"], [null, "Search"]]
// where "root" is the identifier, and "Home" is the display name
export const Breadcrumbs = ({ breadcrumbs }) => {
    if (!breadcrumbs || breadcrumbs.length === 0) {
        return null
    }

    return (
        <div className="breadcrumbs">
            <React.Fragment key={breadcrumbs.index}>{
                    <Link to="/">Home</Link>
                }
            </React.Fragment>
            {/*{breadcrumbs.map((bc, index) => (*/}
            {/*    <React.Fragment key={index}>{*/}
                    {/*{bc[0] === "root" ? (*/}
                    {/*    <Link to="/">Home > </Link>*/}
                    {/*) : (*/}
                    {/*    bc[0] == null*/}
                    {/*        ? (*/}
                    {/*            <span>{bc}</span>*/}
                    {/*        ) : (*/}
                    {/*            <span>{bc} ></span>*/}
                    {/*            // <Link to={`/pages/${bc[0]}`}>{bc[1]}</Link>*/}
                    {/*            // <Link to={`/pages/${bc[0]}`}></Link>*/}
                    {/*        )*/}
                    {/*)}*/}
                    {/*{index < breadcrumbs.length - 1 && (*/}
                    {/*    <span>&nbsp; &gt; &nbsp;</span>*/}
                    {/*)}*/}
                {/*</React.Fragment>*/}
            {/*))}*/}
        </div>
    )
}
