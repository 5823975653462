import React from 'react';

const HighFiveText = () => (
    <g name={"computertext"} transform="translate(0, -12)">

        <text
            fontFamily="SourceSansProRegular, Source Sans Pro"
            fontSize="16"
            fontWeight="normal"
            fill="#767676"
        >
            <tspan x="699" y="272">
                Receive dedicated support from our team{' '}
                of experts to help you overcome{' '}
                challenges and optimize your{' '}
                organization's integration experience.
            </tspan>
        </text>


    </g>
);

export {HighFiveText};

